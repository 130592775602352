import React from 'react';
import styled from 'styled-components';
import arrow from '../images/arrow.png';

import { D } from '@dayetopia/library';

interface Props {
  benefitsDrawerOpen: boolean;
  setBenefitsDrawerOpen: (bool: boolean) => void;
}

export default function QuesWarningBenefitsDrawer({
  benefitsDrawerOpen,
  setBenefitsDrawerOpen,
}: Props) {
  return (
    <D.Drawer
      drawerOpen={benefitsDrawerOpen}
      setDrawerOpen={setBenefitsDrawerOpen}
      title="Benefits"
    >
      <ContentWrapper>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Less painful periods</PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Helps acne</PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Lighter and more regular bleeding patterns</PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>
            Control your bleeding by controlling how you take the pill across
            the month
          </PStyled3>
        </BulletPoint>

        <DrawerSubTitle> Reduced risks of</DrawerSubTitle>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Uterine cancer</PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Ovarian cancer</PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>Colorectal cancer </PStyled3>
        </BulletPoint>
      </ContentWrapper>
    </D.Drawer>
  );
}

const ContentWrapper = styled.div`
  width: 300px;
`;

const DrawerSubTitle = styled(D.P)`
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
`;
export const ArrowIcon = styled.img`
  height: 20px;
  width: 15px;
  padding-top: 5px;
`;
const PStyled3 = styled(D.P)`
  font-size: 16px;
  margin: 0 10px;
`;
const BulletPoint = styled.div`
  display: flex;
  margin: 20px 0;
`;
