import React from 'react';
import styled from 'styled-components';

import sassy from '../images/girl.png';
import { D } from '@dayetopia/library';

export default function QuesWarningLeftContent() {
  return (
    <LeftContent>
      <SassyImgStyled src={sassy} />
      <H1Styled>
        Before you start our contraceptive consultation there's a few things you
        need to know.
      </H1Styled>
      <PStyled>
        Don't take the pill like you'd pop tictacs! It's a powerful medication
        with benefits and side effects that we think everyone should be aware
        of.
      </PStyled>
      <PStyled>
        Oral contraceptives are designed for ovulating women. If you were not
        assigned female at birth or you are taking other hormonal medication,
        please speak directly to a medical professional to help with your
        specific needs.
      </PStyled>
    </LeftContent>
  );
}

const LeftContent = styled.div`
  background-color: #eaf5e8;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  @media only screen and (max-width: 748px) {
    padding-top: 0px;
    padding-bottom: 30px;
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
  }
`;

const SassyImgStyled = styled.img`
  height: 200px;
  @media only screen and (max-width: 500px) {
    margin-top: 30px;
  }
`;

const H1Styled = styled(D.H1)`
  font-size: 28px;
  width: 70%;
  text-align: center;
  margin: 20px 0;
`;

const PStyled = styled(D.P)`
  font-size: 16px;
  width: 70%;
  padding: 10px 0;
  text-align: center;
  @media only screen and (max-width: 500px) {
    padding: 5px 0;
  }
`;
