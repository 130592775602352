import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';

import arrow from '../images/arrow.png';
import { ArrowIcon } from './QuesWarningBenefitsDrawer';

export default function QuesWarningSideEffectDrawer({
  sideEffectsDrawerOpen,
  setSideEffectsDrawerOpen,
}) {
  return (
    <D.Drawer
      drawerOpen={sideEffectsDrawerOpen}
      setDrawerOpen={setSideEffectsDrawerOpen}
      title="Side effects"
    >
      <PStyled3>
        Side effects vary depending on each individual. Some possible side
        effects include:
      </PStyled3>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3>Mood change </PStyled3>
      </BulletPoint>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3>Nausea </PStyled3>
      </BulletPoint>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3>Breast tenderness</PStyled3>
      </BulletPoint>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3> Headache</PStyled3>
      </BulletPoint>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3> Dizziness</PStyled3>
      </BulletPoint>
      <BulletPoint>
        <ArrowIcon src={arrow} />
        <PStyled3>Unexpected bleeding </PStyled3>
      </BulletPoint>
      <PStyled3>
      Most side effects subside after 3-6 months of pill use.
      </PStyled3>
    </D.Drawer>
  );
}

const PStyled3 = styled(D.P)`
  font-size: 16px;
  margin: 0 10px;
  width: 300px;
`;
const BulletPoint = styled.div`
  display: flex;
  margin: 20px 0;
  width: 300px;
`;
