import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';

export default function QuesWarningEffectivenessDrawer({
  effectivenessDrawerOpen,
  setEffectivenessDrawerOpen,
}) {
  return (
    <D.Drawer
      drawerOpen={effectivenessDrawerOpen}
      setDrawerOpen={setEffectivenessDrawerOpen}
      title="Effectiveness"
    >
      <PStyled3>
        The combined pill is over 99% effective at preventing pregnancy with
        perfect use. However, with typical use it’s about 93% effective. This
        means if 10 of your friends is taking the pill, statistically one of
        them will get pregnant almost every year. This is why it’s really
        important to take the pill as instructed.
      </PStyled3>
    </D.Drawer>
  );
}

const PStyled3 = styled(D.P)`
  font-size: 16px;
  margin: 0 10px;
  width: 300px;
`;
