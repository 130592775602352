import React from 'react';
import styled from 'styled-components';

import QuesWarningLeftContent from '../components/QuesWarningLeftContent';
import QuesWarningRightContent from '../components/QuesWarningRightContent';

export default function QuestionnaireWarning() {
  return (
    <PageContainer>
      <QuesWarningLeftContent />
      <QuesWarningRightContent />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: relative;
  background: #eaf5e8;
  display: flex;
  height: calc(100vh-100px);
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`;
