import React, { useState } from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import { D } from '@dayetopia/library';
import sideEffects from '../images/scales.png';
import benefits from '../images/icon-benefits.png';
import effectiveness from '../images/icon-effectiveness.png';
import risks from '../images/icon-risks.png';
import Button from '../components/Button';
import QuesWarningEffectivenessDrawer from '../components/QuesWarningEffectivenessDrawer';
import QuesWarningBenefitsDrawer from '../components/QuesWarningBenefitsDrawer';
import QuesWarningSideEffectDrawer from '../components/QuesWarningSideEffectsDrawer';
import QuesWarningRisksDrawer from '../components/QuesWarningRisksDrawer';

export default function QuesWarningRightContent() {
  const [checkboxChecked, setCheckbox] = useState(false);
  const [effectivenessDrawerOpen, setEffectivenessDrawerOpen] = useState(false);
  const [benefitsDrawerOpen, setBenefitsDrawerOpen] = useState(false);
  const [sideEffectsDrawerOpen, setSideEffectsDrawerOpen] = useState(false);
  const [risksDrawerOpen, setRisksDrawerOpen] = useState(false);

  return (
    <RightContent>
      <TileContainer>
        <Tile>
          <ImageStyled src={effectiveness} />
          <H3Styled>Effectiveness</H3Styled>
          <MoreInfoLink onClick={() => setEffectivenessDrawerOpen(true)}>
            More Info
          </MoreInfoLink>
        </Tile>
        <QuesWarningEffectivenessDrawer
          effectivenessDrawerOpen={effectivenessDrawerOpen}
          setEffectivenessDrawerOpen={setEffectivenessDrawerOpen}
        />

        <Tile>
          <ImageStyled src={benefits}></ImageStyled>
          <H3Styled>Benefits</H3Styled>
          <MoreInfoLink onClick={() => setBenefitsDrawerOpen(true)}>
            More Info
          </MoreInfoLink>
        </Tile>
        <QuesWarningBenefitsDrawer
          benefitsDrawerOpen={benefitsDrawerOpen}
          setBenefitsDrawerOpen={setBenefitsDrawerOpen}
        />

        <Tile>
          <ImageStyled src={sideEffects}></ImageStyled>

          <H3Styled>Side effects</H3Styled>
          <MoreInfoLink onClick={() => setSideEffectsDrawerOpen(true)}>
            More Info
          </MoreInfoLink>
        </Tile>
        <QuesWarningSideEffectDrawer
          sideEffectsDrawerOpen={sideEffectsDrawerOpen}
          setSideEffectsDrawerOpen={setSideEffectsDrawerOpen}
        />

        <Tile>
          <ImageStyled src={risks}></ImageStyled>
          <H3Styled>Risks</H3Styled>
          <MoreInfoLink onClick={() => setRisksDrawerOpen(true)}>
            More Info
          </MoreInfoLink>
        </Tile>

        <QuesWarningRisksDrawer
          risksDrawerOpen={risksDrawerOpen}
          setRisksDrawerOpen={setRisksDrawerOpen}
        />
      </TileContainer>
      <CheckboxContainer onClick={() => setCheckbox(!checkboxChecked)}>
        <D.Checkbox
          checked={checkboxChecked}
          onClick={() => setCheckbox(!checkboxChecked)}
        />
        <PStyled2>
          Please tick to confirm you have read the above, and understand the
          risks and benefits involved with taking a new contraceptive pill.
        </PStyled2>
      </CheckboxContainer>
      <Link to="/questionnaire">
        <Button style={{ marginBottom: '30px' }} disabled={!checkboxChecked}>
          Continue
        </Button>
      </Link>
    </RightContent>
  );
}

const CheckboxContainer = styled.div`
  margin-top: 50px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 80%;
  }
`;

const PStyled2 = styled(D.P)`
  font-size: 16px;
  margin-top: -7px;
  margin-left: 10px;
`;

const TileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(210px, 1fr));
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 50px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(140px, 1fr));
    grid-column-gap: 27px;
  }
`;
const Tile = styled.div`
  border: 2px solid #00391e;
  background: #fefcea;
  max-height: 200px;
  max-width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const H3Styled = styled(D.H3)`
  text-transform: uppercase;
  font-family: BrownStd;
  font-size: 16px;
  letter-spacing: 1.6px;
  margin: 25px auto 15px auto;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const MoreInfoLink = styled(D.P)`
  text-decoration: underline;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-family: BrownStd;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
`;

const ImageStyled = styled.img`
  height: 50px;
  margin-top: 20px;
`;

const RightContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #cae2c3;
  min-height: calc(100vh - 100px);

  @media only screen and (max-width: 748px) {
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
    padding-top: 30px;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
  }
`;
