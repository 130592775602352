import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import arrow from '../images/arrow.png';
import { ArrowIcon } from './QuesWarningBenefitsDrawer';
export default function QuesWarningRisksDrawer({
  risksDrawerOpen,
  setRisksDrawerOpen,
}) {
  return (
    <D.Drawer
      drawerOpen={risksDrawerOpen}
      setDrawerOpen={setRisksDrawerOpen}
      title="Risks"
    >
      <ContentWrapper>
        <PStyled3>
          The combined contraceptive pill has some serious – albeit rare – risks you should be aware of. However, if you're otherwise fit and
          healthy (i.e do not have any risk factors), the *actual* risk of these
          things happening is very, very low. It's very similar to flying
          long-distance - everyone knows that it increases the risk of blood
          clots in your leg (likely 3x your baseline risk), but if do not have
          any risk factors, the chances of it happening on your next flight are
          not worth worrying about..
        </PStyled3>

        <DrawerSubTitle>Risk factors to be aware of include:</DrawerSubTitle>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>
            History of blood clots, clotting disorder, family history of blood
            clots (under the age of 45), pregnancy or just had a baby. <br />
            Enough actual risk of deep vein thrombosis (blood clots in your leg)
            to consider another option.
          </PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />

          <PStyled3>
            High blood pressure, migraines with aura, smoking or age over 35
            <br />
            Enough actual risk of strokes/heart attacks to consider another
            option.
          </PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>
            History of or family history of cervical or breast cancer
            <br></br>
            Enough actual risk of these cancers to consider another option
          </PStyled3>
        </BulletPoint>
        <BulletPoint>
          <ArrowIcon src={arrow} />
          <PStyled3>
            History of blood clots, clotting disorder, family history of blood
            clots (under the age of 45), pregnancy or just had a baby. <br></br>
            Enough actual risk of deep vein thrombosis (blood clots in your leg)
            to consider another option.
          </PStyled3>
        </BulletPoint>
        <PStyled3>
          To put it into the perspective, most contraceptive pills increase your
          clotting risk 3x (same as flying), whereas being pregnant increases
          your risk 5x and women who have just had a baby 60x!
        </PStyled3>
      </ContentWrapper>
    </D.Drawer>
  );
}

const ContentWrapper = styled.div`
  width: 570px;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const PStyled3 = styled(D.P)`
  font-size: 16px;
  margin: 0 10px;
`;

const DrawerSubTitle = styled(D.P)`
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
`;

const BulletPoint = styled.div`
  display: flex;
  margin: 20px 0;
`;
